import React from 'react';

const ConsentTermsTR = () => (
  <>
    <h3>Termo de Consentimento de Tratamento de Dado Pessoal - Legal One Analytics</h3>
    <p>
      Pelo presente Termo de Consentimento de Coleta e Tratamento de Dados Pessoais (“Termo de
      Consentimento”), você, doravante denominado “Titular”,
      {' '}
      <b>declara autorizar e consentir</b>
      {' '}
      que a Juristec Sistemas Jurídicos Integrados Ltda, inscrita no CNPJ/MF sob o nº
      27.434.472/0001-09 (“
      <b>Juristec+</b>
      ”), colete e trate seus dados pessoais necessários para utilização da plataforma
      {' '}
      <b>Legal One Analytics</b>
      , nos termos do art. 7º, I, art. 8º, § 1º e demais dispositivos da Lei Geral de Proteção
      de Dados Pessoais nº 13.709/2018 (“LGPD”), conforme os seguintes critérios e finalidades
      e de acordo com a
      {' '}
      <a
        href="https://juristecplus.com/politica-de-privacidade/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Política de Privacidade e Proteção de Dados Pessoais
      </a>
      {' '}
      constante em nosso site
      {' '}
      <a
        href="https://juristecplus.com/politica-de-privacidade/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://juristecplus.com/politica-de-privacidade/
      </a>
      .
    </p>

    <h3 className="section">1. Definições</h3>
    <p>
      Para fins do presente Termo de Consentimento, os termos aqui constantes em maiúsculo contém
      as definições detalhadas ao longo do texto ou constantes em nossa
      {' '}
      <b>Política de Privacidade e Proteção de Dados Pessoais</b>
      .
    </p>

    <h3 className="section">2. Atuação como Controladora do Tratamento e Encarregado Responsável</h3>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      {' '}
      atua como Controladora do Tratamento dos dados pessoais objeto do presente Termo de
      Consentimento. Quaisquer dúvidas ou solicitações pelo Titular relativas a seus dados pessoais
      ou demais assuntos envolvendo a LGPD deverão ser endereçadas diretamente ao Encarregado da
      {' '}
      <b>Juristec+</b>
      , no e-mail:  privacidade_de_dados@juristecplus.com.
    </p>
    <p>
      Além das hipóteses definidas no presente Termo de Consentimento, a
      {' '}
      <b>Juristec+</b>
      {' '}
      poderá atuar como Controladora em demais situações envolvendo tratamento de dados pessoais
      como, por exemplo, no tratamento envolvendo a utilização de outros produtos que demandam
      termos de consentimento próprios ou demais situações constantes em nossa
      {' '}
      <b>Política de Privacidade e Proteção de Dados Pessoais</b>
      . Ainda, a depender da ocasião, a
      {' '}
      <b>Juristec+</b>
      {' '}
      poderá atuar como Operadora ou Suboperadora no tratamento de dados pessoais, conforme demanda
      de Clientes e Parceiros.
    </p>

    <h3 className="section">3. Dados Pessoais Coletados e sua Finalidade</h3>
    <h4>
      3.1 - Dados pessoais tratados para realização de login de acesso e segurança de nossa
      plataforma
      {' '}
      <i>Legal One Analytics</i>
    </h4>
    <p>
      O Titular fornece o devido consentimento à
      {' '}
      <b>Juristec+</b>
      {' '}
      para que realizemos o Tratamento dos seguintes dados pessoais durante a visitação ou uso da
      nossa plataforma
      {' '}
      <b>Legal One Analytics</b>
      , para a finalidade de garantir a identificação e segurança de nossos Serviços:
    </p>
    <ol type="I">
      <li>
        nome completo, nome de tratamento, foto de perfil, gênero, data de nascimento,
        nacionalidade, naturalidade e informações de identidade, como informações de identificação
        emitidas pelo governo, identificadores de impostos, números de seguro social, outros
        identificadores emitidos pelo governo e dados semelhantes;
      </li>
      <li>
        números de telefone e de celular, endereços de e-mail e endereços de residência;
      </li>
      <li>
        login, senha e outros métodos e funções de autenticação;
      </li>
      <li>
        além dos dados pessoais acima, a utilização de Cookies e Tecnologias Semelhantes, nos
        termos constantes em nossa
        {' '}
        <b>Política de Privacidade e Proteção de Dados</b>
        , necessários para funcionamento e segurança de nossos sites e plataformas.
      </li>
    </ol>
    <h4>
      3.2 - Dados ppessoais tratados para garantia da execução de nossos serviços, prestação de
      suporte, realização de cobrança, adoção de campanhas de marketing, vendas e melhor
      experiência de usuários e visitantes
    </h4>
    <p>
      O Titular fornece o devido consentimento à
      {' '}
      <b>Juristec+</b>
      {' '}
      para que realizemos o Tratamento dos seguintes dados pessoais para a finalidade de garantir
      a execução de nossos serviços, prestação de suporte, realização de cobrança, adoção de
      campanhas de marketing, vendas e melhor experiência de usuários e visitantes:
    </p>
    <ol type="I">
      <li>
        nome completo, nome de tratamento, foto de perfil, gênero, data de nascimento,
        nacionalidade, naturalidade e informações de identidade, como informações de
        identificação emitidas pelo governo, identificadores de impostos, números de
        seguro social, outros identificadores emitidos pelo governo e dados semelhantes;
      </li>
      <li>
        informações de perfil pessoal e profissional, como estado civil, cargo, profissão,
        formação, empregador ou organizações das quais o Titular é membro e dados semelhantes;
      </li>
      <li>
        números de telefone e de celular, endereços de e-mail e endereços de residência;
      </li>
      <li>
        e-mails, mensagens de texto e voz, realização de chamadas de vídeo e voz, documentos
        encaminhados pelo Titular e outros dados e comunicações semelhantes durante as interações
        realizadas com a equipe da
        {' '}
        <b>Juristec+</b>
        {' '}
        e Parceiros, inclusive durante a realização de cursos e treinamento;
      </li>
      <li>
        informações financeiras, como histórico de compras, endereço de cobrança, informações de
        cartão de crédito, número de conta bancária e dados semelhantes;
      </li>
      <li>
        além dos dados pessoais acima, a utilização, nos termos constantes em nossa
        {' '}
        <b>Política de Privacidade e Proteção de Dados</b>
        , de Cookies e Tecnologias Semelhantes: (a) necessários para funcionamento e segurança de
        nossos sites e plataformas; (b) que visem a melhoria do desempenho de nossos sites ou
        plataformas; (c) que permitam que nossos sites ou plataformas obtenham informações sobre
        o comportamento e preferências de visitantes e usuários; e (d) para identificação de
        perfis de interesses dos usuários e mostrar-lhes anúncios relevantes em websites próprios
        e de terceiros.
      </li>
    </ol>
    <h4>
      3.3 - Dados pessoais tratados para fins de cadastro, relacionamento e realização de transações
      financeiras
    </h4>
    <p>
      O Titular fornece o devido consentimento à
      {' '}
      <b>Juristec+</b>
      {' '}
      para que realizemos o Tratamento dos seguintes dados pessoais durante a visitação ou uso da
      nossa plataforma
      {' '}
      <b>Legal One Analytics</b>
      , para a finalidade de realizar cadastros, firmar contratos, trocar comunicações e realizar
      transações financeiras:
    </p>
    <ol type="I">
      <li>
        nome completo, nome de tratamento, foto de perfil, gênero, data de nascimento,
        nacionalidade, naturalidade e informações de identidade, como informações de
        identificação emitidas pelo governo, identificadores de impostos, números de seguro social,
        outros identificadores emitidos pelo governo e dados semelhantes;
      </li>
      <li>
        números de telefone e de celular, endereços de e-mail e endereços de residência;
      </li>
      <li>
        informações de perfil pessoal e profissional, como estado civil, cargo, profissão, formação,
        currículo, histórico escolar, cópia de certificados educacionais e de registros perante
        entidades de classe, empregador ou organizações das quais o Titular é membro e dados
        semelhantes;
      </li>
      <li>
        e-mails, mensagens de texto e voz, realização de chamadas de vídeo e voz, documentos
        encaminhados pelo Titular e outros dados e comunicações semelhantes durante as interações
        realizadas com a equipe da
        {' '}
        <b>Juristec+</b>
        {' '}
        e Parceiros, inclusive durante a realização de cursos e treinamento;
      </li>
      <li>
        informações financeiras, como endereço de cobrança, informações de cartão de crédito,
        número de conta bancária, comprovantes de bens e renda e dados semelhantes; e
      </li>
      <li>
        informações obtidas de fontes públicas ou tornadas manifestamente públicas pelo Titular,
        como aquelas constantes em sites de entidades do Setor Público, banco de dados públicos ou
        certidões, processos e procedimentos administrativos públicos.
      </li>
    </ol>

    <h3 className="section">4. Forma e Compartilhamento do Tratamento</h3>
    <p>
      Os dados pessoais relacionados na Cláusula 3ª poderão, nos termos da LGPD, ser tratados via
      coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão,
      distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da
      informação, modificação, comunicação, transferência, difusão ou extração, por meio físico ou
      eletrônico, por colaboradores da
      {' '}
      <b>Juristec+</b>
      , de maneira manual, automatizada ou por envio ou preenchimento pelo próprio Titular
      (“Tratamento”).
    </p>
    <p>
      O Titular fornece o devido consentimento à
      {' '}
      <b>Juristec+</b>
      {' '}
      para que os dados acima sejam compartilhados entre a
      {' '}
      <b>Juristec+</b>
      {' '}
      e os Parceiros envolvidos na operação de Tratamento, conforme a lista de parceiros constante
      em nossa
      {' '}
      <b>Política de Privacidade e Proteção de Dados Pessoais</b>
      , de maneira física ou eletrônica. Os dados também poderão ser compartilhados com o Cliente
      que o Titular seja integrante da organização e com o próprio Titular. Por fim, os dados
      poderão ser excepcionalmente compartilhados com Autoridades Públicas ou terceiros quando
      necessário para o cumprimento de obrigação legal ou regulatória, para o exercício regular
      de direitos em processo judicial, administrativo ou arbitral ou nas demais hipóteses
      permitidas pela LGPD.
    </p>
    <p>
      Os dados pessoais do Titular, bem como Cookies e Tecnologias Semelhantes, poderão ser
      transferidos, armazenados e tratados em território estrangeiro pela
      {' '}
      <b>Juristec+</b>
      , Clientes e Parceiros, nos termos de nossa
      {' '}
      <b>Política de Privacidade e Proteção de Dados Pessoais</b>
      .
    </p>

    <h3 className="section">5. Duração do Tratamento e Eliminação dos Dados Pessoais</h3>
    <p>
      Os dados poderão ser tratados tanto pela
      {' '}
      <b>Juristec+</b>
      {' '}
      quanto por nossos Clientes e Parceiros enquanto necessário para a execução das finalidades
      constantes da Cláusula 3º e poderão permanecer armazenados pelo prazo prescricional
      necessário para resguardar direitos da
      {' '}
      <b>Juristec+</b>
      , de nossos Clientes e Parceiros. O término do Tratamento se dará:
    </p>
    <ol type="a">
      <li>
        quando atingida a finalidade e após o término do prazo prescricional necessário para
        resguardar direitos da
        {' '}
        <b>Juristec+</b>
        , de nossos Clientes e Parceiros;
      </li>
      <li>
        mediante solicitação expressa do Titular para dados pessoais em que o consentimento para
        Tratamento é necessário;
      </li>
      <li>
        mediante determinação da ANPD, em caso de violação ao ordenamento jurídico e à LGPD.
      </li>
    </ol>
    <p>
      Finalizado o Tratamento, os dados pessoais deverão ser eliminados pela
      {' '}
      <b>Juristec+</b>
      , salvo nas hipóteses de:
    </p>
    <ol type="a">
      <li>
        manutenção necessária para o cumprimento de obrigações legais ou regulatórias pela
        {' '}
        <b>Juristec+</b>
        , Clientes e Parceiros;
      </li>
      <li>
        transferência a terceiro, desde que respeitados os requisitos de tratamento de dados
        dispostos na LGPD;
      </li>
      <li>
        uso exclusivo da
        {' '}
        <b>Juristec+</b>
        , vedado seu acesso por terceiro, e desde que sujeitos a processo de anonimização; e
      </li>
      <li>
        demais hipóteses permitidas pelo ordenamento jurídico e pela LGPD.
      </li>
    </ol>

    <h3 className="section">6. Direitos constantes da LGPD</h3>
    <p>
      A LGPD, confere, em seu art. 18, os seguintes direitos ao Titular:
    </p>
    <p>
      "Art. 18. O titular dos dados pessoais tem direito a obter do controlador, em relação aos
      dados do titular por ele tratados, a qualquer momento e mediante requisição:
    </p>
    <ol type="I">
      <li>
        Confirmação da existência de tratamento;
      </li>
      <li>
        Acesso aos dados;
      </li>
      <li>
        Correção de dados incompletos, inexatos ou desatualizados;
      </li>
      <li>
        Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em
        desconformidade com o disposto nesta Lei;
      </li>
      <li>
        Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição
        expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos
        comercial e industrial;
      </li>
      <li>
        Eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas
        hipóteses previstas no art. 16 desta Lei;
      </li>
      <li>
        informação das entidades públicas e privadas com as quais o controlador realizou uso
        compartilhado de dados;
      </li>
      <li>
        informação sobre a possibilidade de não fornecer consentimento e sobre as consequências
        da negativa;
      </li>
      <li>
        Revogação do consentimento, nos termos do § 5º do art. 8º desta Lei.
      </li>
    </ol>
    <p>
      § 1º O titular dos dados pessoais tem o direito de peticionar em relação aos seus dados
      contra o controlador perante a autoridade nacional.
    </p>
    <p>
      § 2º O titular pode opor-se a tratamento realizado com fundamento em uma das hipóteses de
      dispensa de consentimento, em caso de descumprimento ao disposto nesta Lei.
    </p>
    <p>
      § 3º Os direitos previstos neste artigo serão exercidos mediante requerimento expresso do
      titular ou de representante legalmente constituído, a agente de tratamento.
    </p>
    <p>
      § 4º Em caso de impossibilidade de adoção imediata da providência de que trata o § 3º
      deste artigo, o controlador enviará ao titular resposta em que poderá:
      <ol type="I">
        <li>
          Comunicar que não é agente de tratamento dos dados e indicar, sempre que possível, o
          agente; ou
        </li>
        <li>
          Indicar as razões de fato ou de direito que impedem a adoção imediata da providência.
        </li>
      </ol>
    </p>
    <p>
      § 5º O requerimento referido no § 3º deste artigo será atendido sem custos para o titular,
      nos prazos e nos termos previstos em regulamento.
    </p>
    <p>
      § 6º O responsável deverá informar, de maneira imediata, aos agentes de tratamento com os
      quais tenha realizado uso compartilhado de dados a correção, a eliminação, a anonimização
      ou o bloqueio dos dados, para que repitam idêntico procedimento, exceto nos casos em que
      esta comunicação seja comprovadamente impossível ou implique esforço desproporcional.
    </p>
    <p>
      § 7º A portabilidade dos dados pessoais a que se refere o inciso V do caput deste artigo
      não inclui dados que já tenham sido anonimizados pelo controlador.
    </p>
    <p>
      § 8º O direito a que se refere o § 1º deste artigo também poderá ser exercido perante os
      organismos de defesa do consumidor."
    </p>
    <p>
      O Titular poderá, a qualquer momento, contatar a Juristec+, através do e-mail do Encarregado:
      privacidade_de_dados@juristecplus.com para pleitear o exercício de quaisquer dos direitos
      constantes na LGPD, incluindo os direitos de (i) acessar seus dados pessoais; (ii) retificar
      seus dados pessoais; (iii) restringir a extensão do tratamento; (iv) revogar o consentimento
      dado; (v) excluir seus dados pessoais; ou (vi) pleitear os demais direitos constantes na LGPD.
    </p>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      {' '}
      reserva-se o direito de não atender parcial ou integralmente pedidos do Titular previsto no
      art. 18 e demais dispositivos da LGDP nas hipóteses em que: (i) a própria LGPD permitir à
      {' '}
      <b>Juristec+</b>
      {' '}
      solução diversa da pleiteada pelo Titular; (ii) alterações ou especificações legislativas
      e/ou regulamentares liberarem a
      {' '}
      <b>Juristec+</b>
      {' '}
      da obrigação pleiteada pelo Titular, como, por exemplo, disposições constantes da Resolução
      CD/ANPD nº 2/2022; ou (iii) os pleitos do Titular estiverem em confronto com o ordenamento
      jurídico e/ou jurisprudência do Poder Judiciário brasileiro.
    </p>

    <h3 className="section">7. Segurança no Tratamento de Dados Pessoais</h3>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      {' '}
      dá total prioridade para a segurança no tratamento de dados pessoais. Nosso Sistema de Gestão
      de Privacidade da Informação contém as políticas e controles necessários desenvolvidos para
      proteger a segurança de dados pessoais do Titular.
    </p>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      {' '}
      adota práticas de mercado aderentes ao seu porte e perfil de negócio. Ainda, a
      {' '}
      <b>Juristec+</b>
      {' '}
      tem o compromisso de constantemente atualizar e aprimorar nossas regras e controles de
      segurança da informação, de maneira a proteger dentro dos padrões de mercado os dados pessoais
      de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda,
      alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.
    </p>
    <p>
      Em conformidade e nos termos do art. 48 da LGPD, a
      {' '}
      <b>Juristec+</b>
      {' '}
      comunicará ao Titular e à ANPD a ocorrência de incidente de segurança que possa acarretar
      risco ou dano relevante ao Titular.
    </p>

    <h3 className="section">8. Responsabilidades</h3>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      , no exercício das atividades de tratamento de dados pessoais, poderá ser obrigada, nos termos
      do art. 42 e seguintes da LGPD, a reparar os danos patrimoniais, morais, individuais ou
      coletivos causados ao Titular em decorrência da violação à legislação de proteção de dados
      pessoais.
    </p>
    <p>
      O Titular declara ter ciência que a
      {' '}
      <b>Juristec+</b>
      {' '}
      não será responsável, nos termos do art. 43 da LGPD e demais legislação aplicável, por
      quaisquer danos patrimoniais, morais, individuais ou coletivos relacionados a alegadas
      violações à LGPD quando provar que (i) não realizou o tratamento de dados pessoais que lhe
      é atribuído; (ii) embora tenha realizado o tratamento de dados pessoais que lhe é atribuído,
      não houve violação à legislação de proteção de dados; ou (iii) o dano é decorrente de culpa
      exclusiva do Titular ou de terceiros.
    </p>

    <h3 className="section">9. Demais Disposições</h3>
    <p>
      O Titular declara ter ciência que a
      {' '}
      <b>Juristec+</b>
      , resguardados os demais direitos e princípios previstos na LGPD, reserva-se o direito de
      realizar, mediante a dispensa de consentimento, o tratamento de dados pessoais de usuários,
      visitantes e terceiros, informados na
      {' '}
      <b>Política de Privacidade e Proteção de Dados Pessoais</b>
      , nos seguintes casos: (i) para o cumprimento de obrigação legal ou regulatória pelo
      Controlador; (ii) para a execução de contrato ou de procedimentos preliminares relacionados
      a contrato do qual seja parte o Titular, a pedido do Titular dos dados; (iii) para o
      exercício regular de direitos em processo judicial, administrativo ou arbitral; (iv) para
      a proteção da vida ou da incolumidade física do Titular ou de terceiro; (v) quando necessário
      para atender aos interesses legítimos do Controlador ou de terceiros, exceto no caso de
      prevalecerem direitos e liberdades fundamentais do titular que exijam a proteção dos dados
      pessoais; (vi) para a proteção do crédito, inclusive quanto ao disposto na legislação
      pertinente; e (vii) pessoais cujo acesso é público ou tornados manifestamente públicos
      pelo Titular.
    </p>
    <p>
      O presente Termo de Consentimento é regido de acordo com as leis brasileiras. Fica eleito o
      Foro da Comarca de São Paulo, SP, com expressa renúncia a qualquer outro, por mais
      privilegiado que seja, para dirimir as questões oriundas de quaisquer obrigações e
      interpretações do presente Termo de Consentimento.
    </p>
    <p>
      O Titular declara e concorda que o presente Termo de Consentimento é assinado de maneira
      eletrônica, cuja validade é reconhecida pelo Titular, nos termos do § 2º do art. 10 da
      Medida Provisória nº 2.200-2/2001.
    </p>

    <h3 className="section">Da Capacidade Civil</h3>
    <p>
      O Titular, neste ato, declara ter mais de 18 (dezoito) anos e estar em plena capacidade para
      a prática de todos os atos da vida civil.
    </p>

    <h3 className="section">Do Livre Consentimento</h3>
    <p>
      O Titular declara ter lido e ter sido suficientemente informado sobre o conteúdo deste Termo
      de Consentimento,
      {' '}
      <b>
        anuindo com as disposições constantes neste instrumento e na Política de Privacidade e
        Proteção de Dados Pessoais, concordando com o Tratamento dos seus dados pessoais aqui
        descritos de forma livre e esclarecida
      </b>
      , em observância à LGPD e às demais normas sobre proteção de dados pessoais aplicáveis.
    </p>
  </>
);

export default ConsentTermsTR;
