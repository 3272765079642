import React from 'react';

const UseTermsTR = () => (
  <>
    <h3>Termos e Condições Gerais de Uso do Sistema Legal One Analytics</h3>

    <p>
      Os serviços do sistema
      {' '}
      <b>Legal One Analytics</b>
      {' '}
      são fornecidos pela
      {' '}
      <b>Juristec</b>
      {' '}
      Sistemas Jurídicos Integrados Ltda ("
      <b>Juristec</b>
      "), pessoa
      jurídica de direito privado, inscrita no CNPJ/MF sob o nº 27.434.472/0001-09, titular
      da propriedade intelectual sobre entre outros ativos relacionados à plataforma
      {' '}
      <b>Legal One Analytics</b>
      . Referidos serviços são disponibilizados aos usuários pela
      {' '}
      <b>Thomson Reuters Brasil</b>
      {' '}
      Conteúdo e Tecnologia Ltda ("
      <b>Thomson Reuters</b>
      "), pessoa jurídica de direito privado,
      inscrita no CNPJ/MF sob o nº 00.910.509/0001-71, por meio de parceria firmada entre
      {' '}
      <b>Juristec</b>
      {' '}
      e
      {' '}
      <b>Thomson Reuters</b>
      .
    </p>

    <h3 className="section">1. Do Objeto</h3>
    <p>
      A plataforma visa licenciar o uso de seu software, website, aplicativos e demais ativos de
      propriedade intelectual, fornecendo ferramentas para auxiliar e dinamizar o dia a dia dos
      seus usuários.
    </p>
    <p>
      A plataforma caracteriza-se pela prestação dos serviços de “business intelligence” jurídico,
      que pode ser qualificado como um sistema de processamento de bases de dados na forma de
      relatórios e transformação dos mesmos em modelos gráficos por meio da aplicação de
      templates, cópia de dashboards ou criação de novos indicadores.
    </p>
    <p>
      A plataforma é comercializada através do site de sua provedora
      {' '}
      <a
        href="https://www.thomsonreuters.com.br/pt/juridico/legal-one.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.thomsonreuters.com.br/pt/juridico/legal-one.html
      </a>
      {' '}
      e acessada através do site da URL
      {' '}
      <a
        href="https://legalone-analytics.com.br/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://legalone-analytics.com.br/
      </a>
      .
    </p>

    <h3 className="section">2. Da Aceitação</h3>
    <p>
      O presente Termo estabelece obrigações contratadas de livre e espontânea vontade,
      entre a plataforma e as pessoas físicas ou jurídicas, usuárias do software
      {' '}
      <b>Legal One Analytics</b>
      .
    </p>
    <p>
      Ao utilizar a plataforma
      {' '}
      <b>Legal One Analytics</b>
      , o usuário aceita integralmente as presentes normas e compromete-se a observá-las.
    </p>
    <p>
      A aceitação do presente instrumento é imprescindível para o acesso e para a utilização de
      quaisquer serviços fornecidos pela empresa. Caso não concorde com as disposições deste
      instrumento, o usuário não deve utilizá-los.
    </p>

    <h3 className="section">3. Do Acesso Dos Usuários</h3>
    <p>
      Serão utilizadas todas as soluções técnicas à disposição do responsável pela plataforma
      para permitir o acesso ao serviço 24 (vinte e quatro) horas por dia, 7 (sete) dias por
      semana. No entanto, a navegação na plataforma ou em alguma de suas páginas poderá ser
      interrompida, limitada ou suspensa para atualizações, modificações, ações necessárias ao
      seu bom funcionamento e/ou por força de terceiros.
    </p>

    <h3 className="section">4. Do Cadastro</h3>
    <p>
      O acesso às funcionalidades da plataforma exigirá a realização de um cadastro prévio e, a
      depender dos serviços ou produtos escolhidos, o pagamento de determinado valor.
    </p>
    <p>
      Ao se cadastrar, o usuário deverá informar dados completos, recentes e válidos, sendo de sua
      exclusiva responsabilidade manter referidos dados atualizados, bem como o usuário se
      compromete com a veracidade dos dados fornecidos.
    </p>
    <p>
      O usuário se compromete a não informar seus dados cadastrais e/ou de acesso à plataforma a
      terceiros, responsabilizando-se integralmente pelo uso que deles seja feito.
    </p>
    <p>
      Menores de 18 anos e aqueles que não possuírem plena capacidade civil deverão obter
      previamente o consentimento expresso de seus responsáveis legais para utilização da
      plataforma e dos serviços ou produtos, sendo de responsabilidade exclusiva dos mesmos o
      eventual acesso por menores de idade e por aqueles que não possuem plena capacidade civil
      sem a prévia autorização.
    </p>
    <p>
      Mediante a realização do cadastro o usuário declara e garante expressamente ser plenamente
      capaz, podendo exercer e usufruir livremente dos serviços e produtos.
    </p>
    <p>
      Após a confirmação do cadastro, o usuário possuirá um login e uma senha pessoal
      disponibilizada pela
      {' '}
      <b>Thomson Reuters</b>
      , a qual assegura ao usuário o acesso individual
      à mesma. Desta forma, compete ao usuário exclusivamente a manutenção de referida senha
      de maneira confidencial e segura, evitando o acesso indevido às informações pessoais.
    </p>
    <p>
      Toda e qualquer atividade realizada com o uso da senha será de responsabilidade do usuário,
      que deverá informar prontamente a plataforma em caso de uso indevido da respectiva senha.
    </p>
    <p>
      Caberá ao usuário assegurar que o seu equipamento seja compatível com as características
      técnicas que viabilize a utilização da plataforma e dos serviços ou produtos.
    </p>
    <p>
      O usuário poderá requerer o cancelamento de seu cadastro junto ao sistema
      {' '}
      <b>Legal One Analytics</b>
      {' '}
      diretamente à
      {' '}
      <b>Thomson Reuters</b>
      .
    </p>
    <p>
      O usuário, ao aceitar os Termos e Condições Gerais de Uso do Sistema
      {' '}
      <b>Legal One Analytics</b>
      {' '}
      e o Termo de consentimento de Tratamento de Dados Pessoais, autoriza expressamente a
      plataforma a coletar, usar, armazenar, tratar, ceder ou utilizar as informações derivadas
      do uso dos serviços, do site e suas plataformas, incluindo informações preenchidas pelo
      usuário no momento em que realizar ou atualizar seu cadastro, além de outras, incluindo
      informações relacionadas a dados pessoais nas hipóteses e condições definidas no Termo
      de Consentimento de Tratamento de Dados Pessoais e na Política de Privacidade da
      {' '}
      <b>Juristec</b>
      .
    </p>

    <h3 className="section">5. Dos Serviços ou Produtos</h3>
    <p>
      A plataforma poderá disponibilizar para o usuário um conjunto específico de funcionalidades
      e ferramentas para otimizar o uso dos serviços e produtos.
    </p>
    <p>
      Os serviços ou produtos oferecidos estarão descritos no site da provedora:
      {' '}
      <a
        href="https://juristecplus.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://juristecplus.com/
      </a>
      {' '}
      e nas documentações competentes, contendo informações sobre suas características,
      qualidades, quantidades, composição, preço, garantia, prazos de validade e origem,
      entre outros dados, bem como sobre os riscos que apresentam à saúde e segurança do
      usuário.
    </p>
    <p>
      Antes de finalizar a compra sobre determinado produto ou serviço, o usuário deverá se
      informar sobre as suas especificações e sobre a sua destinação.
    </p>

    <h3 className="section">6. Dos Preços</h3>
    <p>
      A plataforma se reserva no direito de reajustar os valores dos serviços ou produtos
      nos termos comerciais definidos entre
      {' '}
      <b>Juristec</b>
      {' '}
      e
      {' '}
      <b>Thomson Reuters</b>
      .
    </p>
    <p>
      Na contratação de determinado serviço ou produto, a plataforma poderá solicitar as
      informações financeiras do usuário, como CPF, endereço de cobrança e dados de cartões. Ao
      inserir referidos dados o usuário concorda que sejam cobrados, de acordo com a forma de
      pagamento que venha a ser escolhida, os preços então vigentes e informados quando da
      contratação. Referidos dados financeiros poderão ser armazenados para facilitar acessos e
      contratações futuras.
    </p>

    <h3 className="section">7. Do Cancelamento</h3>
    <p>
      O usuário poderá cancelar a contratação dos serviços de acordo com os termos que forem
      definidos no momento de sua contratação junto à
      {' '}
      <b>Thomson Reuters</b>
      . Ainda, o usuário também
      poderá cancelar os serviços em até 7 (sete) dias após a contratação, de acordo com o
      {' '}
      <a
        href="https://www.jusbrasil.com.br/legislacao/91585/c%C3%B3digo-de-defesa-do-consumidor-lei-8078-90"
        target="_blank"
        rel="noopener noreferrer"
      >
        Código de Defesa do Consumidor
      </a>
      {' '}
      (Lei no.
      {' '}
      <a
        href="https://www.jusbrasil.com.br/legislacao/91585/c%C3%B3digo-de-defesa-do-consumidor-lei-8078-90"
        target="_blank"
        rel="noopener noreferrer"
      >
        8.078/90
      </a>
      ), mediante contato com a
      {' '}
      <b>Thomson Reuters</b>
      .
    </p>

    <h3 className="section">8. Jurisdição Para Resolução de Conflitos</h3>
    <p>
      Para a solução de controvérsias decorrentes do presente instrumento será aplicado
      integralmente o direito brasileiro.
    </p>
    <p>
      Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra
      a sede da
      {' '}
      <b>Juristec</b>
      .
    </p>
  </>
);

export default UseTermsTR;
