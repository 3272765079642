import React from 'react';

const PolicyTR = () => (
  <>
    <h3>Política de Privacidade e Proteção de Dados Pessoais</h3>
    <h3 className="section">1. Introdução - Declaração Juristec+</h3>
    <h4>Declaração da Juristec+ a Você, o Titular do Dado Pessoal</h4>
    <p>
      Nós da
      {' '}
      <b>Juristec+</b>
      {' '}
      respeitamos e valorizamos a sua privacidade. Além disso, nos preocupamos em ser transparentes
      em como e quando suas informações e dados pessoais são coletados, compartilhados e mantidos
      quando da utilização de nossos produtos, serviços e atividades em geral.
    </p>
    <p>
      Esta
      {' '}
      <b>Política de Privacidade e Proteção de Dados Pessoais</b>
      {' '}
      é direcionada a todos, seja Você um cliente nosso, um colaborador, um parceiro ou um terceiro,
      e descreve as diretrizes por nós adotadas quando do tratamento de seus dados pessoais, em
      observância às melhores práticas da Lei Geral de Proteção de Dados Pessoais nº
      {' '}
      <a
        href="https://www.jusbrasil.com.br/legislacao/612902269/lei-13709-18"
        target="_blank"
        rel="noopener noreferrer"
      >
        13.709/2018
      </a>
      {' '}
      (“LGPD”) e legislações conexas, tendo sempre como objetivo melhor
      {' '}
      <b>informar Você</b>
      {' '}
      sobre seus direitos e questões envolvendo o tratamento de dados pessoais.
    </p>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      {' '}
      assume o compromisso de sempre realizar o tratamento de seus dados pessoais dentro
      das formas e finalidades descritas em nossa
      {' '}
      <a
        href="https://juristecplus.com/politica-de-privacidade/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Política de Privacidade e Proteção de Dados Pessoais
      </a>
      {' '}
      e nos
      {' '}
      <a
        href="https://juristecplus.com/termo-de-consentimento-legal-one-analytics/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Termos de Consentimento
      </a>
      {' '}
      específicos para cada área de nossa atuação, observando sempre as regras e princípios da LGPD.
    </p>
    <p>
      Somos uma empresa de tecnologia contemporânea à LGPD. Nos estruturamos e crescemos tendo como
      pilares do nosso negócio a segurança da informação e a proteção de dados pessoais. O trabalho
      é constante e estamos sempre capacitando nossos profissionais às melhores práticas e
      tecnologias da área.
    </p>
    <p>
      A Você, titular do dado pessoal, conte sempre com nosso cuidado e respeito.
    </p>
    <p className="right">
      <b>Rui Caminha</b>
    </p>
    <p className="right" style={{ marginTop: '2px' }}>
      CEO da Juristec+
    </p>

    <h3 className="section">2. Definições para fins da LGPD</h3>
    <p>
      Esta política de privacidade e proteção de dados pessoais (“
      <b>Política de Privacidade e Proteção de Dados Pessoais</b>
      ”) pertence à Juristec Sistemas Jurídicos Integrados Ltda, empresa constituída sob as leis do
      Brasil e inscrita no CNPJ sob o nº 27.434.472/0001-09 (“
      <b>Juristec+</b>
      ”).
    </p>
    <p>
      Os termos aqui constantes em maiúsculo contém as definições detalhadas ao longo do texto.
    </p>

    <h4>Mas, afinal, quem é o titular de um dado pessoal</h4>
    <p>
      O titular do dado pessoal é pessoa natural a quem se referem os dados pessoais que são objeto
      de tratamento.
    </p>
    <p>
      Para fins da nossa
      {' '}
      <b>Política de Privacidade e Proteção de Dados Pessoais</b>
      , o titular de um dado pessoal (“Titular”) pode ser:
    </p>
    <ol type="I">
      <li>
        o usuário ou visitante pessoa natural de nossas plataformas, sites, aplicativos,
        softwares e demais sistemas da
        {' '}
        <b>Juristec+</b>
        {' '}
        e Parceiros;
      </li>
      <li>
        o Cliente ou o representante ou colaborador de um de nossos Clientes cadastrado na
        {' '}
        <b>Juristec+</b>
        {' '}
        ou em nossos Parceiros;
      </li>
      <li>
        o colaborador da
        {' '}
        <b>Juristec+</b>
        ;
      </li>
      <li>
        os próprios Parceiros da
        {' '}
        <b>Juristec+</b>
        , seus colaboradores, subcontratados e colaboradores dos subcontratados; e
      </li>
      <li>
        Terceiros em geral cujos dados pessoais tenham sido compartilhados com a
        {' '}
        <b>Juristec+</b>
        {' '}
        pelo próprio terceiro, pelos Parceiros e Clientes da
        {' '}
        <b>Juristec+</b>
        {' '}
        ou mediante o acesso de informações públicas ou tornadas manifestamente
        públicas pelo terceiro.
      </li>
    </ol>
    <p>
      Se você se enquadra em alguma das hipóteses acima, você pode ser Titular de algum dado
      pessoal objeto de Tratamento pela
      {' '}
      <b>Juristec+</b>
      , dentro das hipóteses previstas na LGPD e constantes desta
      {' '}
      <b>Política de Privacidade e Proteção de Dados Pessoais</b>
      {' '}
      ou em nossos
      {' '}
      <b>Termos de Consentimento</b>
      .
    </p>

    <h4>E o que é um dado pessoal?</h4>
    <p>
      O conceito de dado pessoal é bem aberto. Ele é definido pela LGPD como a informação
      relacionada a uma pessoa natural identificada ou identificável.
    </p>
    <p>
      Isso quer dizer que dado pessoal pode ser desde uma informação relacionada ao nome do
      Titular, sua idade e informação básica de sua identificação, como sua nacionalidade,
      local de nascimento, R.G., C.P.F. ou endereço de residência.
    </p>
    <p>
      Também pode ser informação relacionada à sua escolaridade, formação, estado civil e
      informações financeiras, incluindo números de contas bancárias, números de cartões de
      crédito etc. Lembre-se,
      {' '}
      <b>o conceito é amplo e aberto</b>
      . Esses são apenas alguns exemplos, sendo também considerados como dados pessoais,
      hábitos de consumo, perfil comportamental, aparência, aspectos da personalidade,
      entre outros.
    </p>

    <h4>E o que são dados pessoais sensíveis</h4>
    <p>
      Dados pessoais sensíveis são aqueles em que a LGPD conferiu uma proteção ainda maior,
      por tratarem de aspectos mais íntimos do Titular.
    </p>
    <p>
      Assim, são dados pessoais sensíveis aqueles relativos à origem racial ou étnica do
      Titular, convicção religiosa, opinião política, filiação a sindicato ou a organização
      de caráter religioso, filosófico ou político, dados referentes à saúde ou à vida
      sexual e dados genéticos ou biométricos.
    </p>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      , como regra geral,
      {' '}
      <b>não realiza o tratamento de dados pessoais sensíveis</b>
      .
    </p>
    <p>
      Excepcionalmente, poderemos realizar o tratamento de um dado pessoal sensível recebido
      por algum de nossos Clientes, Parceiros ou diretamente.
    </p>
    <p>
      Em tais casos, nós observaremos estritamente as regras constantes da LGPD, assim como
      demandaremos o seu cumprimento por nossos Clientes e Parceiros. Somente deverão ser
      tratados dados pessoais sensíveis mediante
      {' '}
      <b>(i)</b>
      {' '}
      consentimento prévio do Titular, indicando qual dado tratado, sua forma de tratamento
      e finalidade ou
      {' '}
      <b>(ii)</b>
      {' '}
      nas hipóteses definidas na LGPD, como, por exemplo: (a) para garantir a segurança do
      Titular e evitar fraudes nos processos de identificação e autenticação de cadastro em
      nossos sistemas eletrônicos e de Parceiros; (b) para o cumprimento de obrigação legal
      ou regulatória; ou (c) para o exercício regular de direitos.
    </p>

    <h4>E o que significa tratar dados pessoais?</h4>
    <p>
      O conceito de tratamento é aberto. Nos termos da LGPD, tratamento de dado pessoal é toda
      operação realizada com dados pessoais, como as que se referem à coleta, produção, recepção,
      classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento,
      arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação,
      comunicação, transferência, difusão ou extração (“Tratamento”).
    </p>

    <h4>O que é o consentimento do Titular do dado pessoal</h4>
    <p>
      Consentimento é a manifestação livre, informada e inequívoca pela qual o Titular concorda
      com o tratamento de seus dados pessoais para uma finalidade determinada.
      {' '}
      <b>É um direito essencial garantido pela LGPD</b>
      .
    </p>
    <p>
      Há casos em que o consentimento do Titular requerido pela LGPD não é aplicável, como nos
      casos envolvendo tratamento realizado por pessoal natural para fins exclusivamente
      particulares e não econômicos ou para fins exclusivamente jornalísticos, artísticos,
      acadêmicos, ou para fins de segurança pública, defesa nacional, segurança do Estado, entre
      outros.
    </p>
    <p>
      Também há situações de tratamento (ressalvados casos de dados pessoais sensíveis e de crianças
      e adolescentes que têm disposições específicas), em que o consentimento do Titular é
      dispensado. São elas:
    </p>
    <ul>
      <li>
        Para o cumprimento de obrigação legal ou regulatória pelo Controlador.
      </li>
      <li>
        Para a execução de políticas públicas, pela administração pública.
      </li>
      <li>
        Para a realização de estudos por órgão de pesquisa.
      </li>
      <li>
        Para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual
        seja parte o Titular, a pedido do Titular dos dados.
      </li>
      <li>
        Para o exercício regular de direitos em processo judicial, administrativo ou arbitral.
      </li>
      <li>
        Para a proteção da vida ou da incolumidade física do Titular ou de terceiros.
      </li>
      <li>
        Para a tutela da saúde, exclusivamente, em procedimento realizado por profissionais de
        saúde, serviços de saúde ou autoridade sanitária.
      </li>
      <li>
        Para atender aos interesses legítimos do Controlador ou de terceiros, exceto no caso de
        prevalecerem direitos e liberdades fundamentais do Titular que exijam a proteção dos
        dados pessoais.
      </li>
      <li>
        Para a proteção do crédito.
      </li>
      <li>
        Dados pessoais cujo acesso é público ou tornados manifestamente públicos pelo Titular,
        resguardados os direitos e princípios previstos na LGDP.
      </li>
    </ul>
    <p>
      Aqui na
      {' '}
      <b>Juristec+</b>
      , além dos casos em que solicitamos o consentimento do Titular, há situações em que
      realizamos diretamente o tratamento de dados pessoais nas hipóteses expressamente definidas
      na LGPD, como nos casos de:
    </p>
    <ol type="I">
      <li>
        tratamento de dados pessoais cujo acesso é público ou tenha se tornado manifestamente
        público pelo Titular;
      </li>
      <li>
        para o cumprimento de obrigações legais ou regulatórias;
      </li>
      <li>
        para a celebração de contrato com o Titular;
      </li>
      <li>
        para o exercício regular de direitos em processo judicial, administrativo ou arbitral; e
      </li>
      <li>
        para atender aos interesses legítimos da Juristec+ ou de terceiros, nas hipóteses permitidas
        pela LGPD.
      </li>
    </ol>

    <h4>Quem são os Controladores, Operadores, Suboperadores e Encarregados?</h4>
    <p>
      <b>Controlador</b>
      {' '}
      é a pessoa natural ou jurídica, de direito público ou privado, a quem compete as decisões
      referentes ao tratamento de dados pessoais (“Controlador”).
    </p>
    <p>
      <b>Operador</b>
      {' '}
      é a pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de
      dados pessoais em nome do controlador (“Operador”).
    </p>
    <p>
      <b>Suboperador</b>
      {' '}
      é a pessoa natural ou jurídica contratada pelo operador para auxiliá-lo a realizar o
      tratamento de dados pessoais em nome do controlador (“Suboperador”).
    </p>
    <p>
      <b>Encarregado</b>
      {' '}
      é a pessoa natural ou jurídica (“Encarregado”) indicada pelo controlador e operador
      para atuar como canal de comunicação entre o controlador, os titulares dos dados e a
      Autoridade Nacional de Proteção de Dados (“ANPD”).
    </p>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      , a depender de quais dados pessoais são tratados, bem como a forma do tratamento,
      poderá atuar como Controladora, responsável pela decisão do tratamento, Operadora,
      realizando o tratamento de dados pessoais em nome de outro Controlador que pode ser
      um terceiro, um Parceiro ou um Cliente da
      {' '}
      <b>Juristec+</b>
      {' '}
      ou Suboperadora.
    </p>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      {' '}
      demandará no compartilhamento de dados pessoais com Controladores, Operadores e
      Suboperadores a estrita observância das obrigações constantes da LGPD.
    </p>
    <p>
      Cabe exclusivamente ao respectivo Controlador, Operador ou Suboperador garantir o
      cumprimento de suas obrigações junto ao Titular e à LGPD, não se responsabilizando
      a
      {' '}
      <b>Juristec+</b>
      {' '}
      por atos por eles realizados em descumprimento das obrigações assumidas perante a
      {' '}
      <b>Juristec+</b>
      {' '}
      e à legislação em vigor.
    </p>
    <p>
      Nos casos em que atuamos como Operador ou Suboperador, as solicitações do Titular
      quanto ao exercício dos direitos constantes da LGPD deverão ser endereçadas
      diretamente ao Controlador, nos termos do art. 18 da LGPD.
    </p>

    <h4>Encarregado da Juristec+</h4>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      {' '}
      possui, dentro de sua Diretoria de Segurança da Informação e Privacidade, o Encarregado
      responsável para atuar como canal de comunicação entre a
      {' '}
      <b>Juristec+</b>
      , os titulares dos dados pessoais e a ANPD.
    </p>
    <p>
      Quaisquer dúvidas ou solicitações pelo Titular relativas a seus dados pessoais deverão
      ser endereçadas diretamente ao Encarregado da
      {' '}
      <b>Juristec+</b>
      {' '}
      no e-mail privacidade_de_dados@juristecplus.com.
    </p>

    <h4>Como se dá a obtenção e compartilhamento de dados pessoais?</h4>
    <p>
      Os dados pessoais podem ser compartilhados de diversas maneiras a depender de cada
      forma e finalidade. Exemplos dos diferentes cenários estão expostos abaixo.
    </p>
    <p>
      <b>Diretamente com o Titular.</b>
      {' '}
      Os dados pessoais podem, primeiramente, ser recebidos do próprio Titular à
      {' '}
      <b>Juristec+</b>
      {' '}
      e com ele compartilhados no caso de troca de informações, mensagens, arquivos etc.
    </p>
    <p>
      <b>Entre a equipe da Juristec+.</b>
      {' '}
      Os dados pessoais do Titular podem, ainda, observadas nossas regras de segurança da
      informação, ser compartilhados internamente dentro da organização da
      {' '}
      <b>Juristec+</b>
      , isto é, transmitidos dentro das diferentes áreas da empresa, como área de recursos
      humanos, departamento financeiro, área comercial, área da segurança da informação e
      privacidade etc.
    </p>
    <p>
      <b>Entre a Juristec+ e seus parceiros.</b>
      {' '}
      No mais, os dados pessoais podem ser recebidos pela
      {' '}
      <b>Juristec+</b>
      {' '}
      de seus parceiros comerciais ou com eles compartilhados. Incluem-se como parceiros
      comerciais (“Parceiros”):
    </p>
    <ol type="I">
      <li>
        empresas de tecnologia, como Grupo Alphabet, Grupo Meta Platforms, Grupo Zendesk,
        Grupo Thomson Reuters, Grupo RD Station, Grupo Microsoft, Grupo Oracle e demais
        empresas prestadoras de serviços de tecnologia da informação, gerenciamento de
        sites, gerenciamento de e-mails, segurança da informação, banco de dados, suporte
        etc.;
      </li>
      <li>
        prestadores de serviços em geral, como escritórios de contabilidade, escritórios
        de advocacia, empresas de auditoria, agências de publicidade, consultorias de
        vendas, prestadores de serviços de segurança e
        {' '}
        <i>facilities</i>
        , entre outros;
      </li>
      <li>
        instituições financeiras em geral, provedores de meios de pagamento, integradores
        de meios de pagamento, empresas de cartões de crédito, empresas de análise de
        crédito, entre outros;
      </li>
      <li>
        empresas parceiras da
        {' '}
        <b>Juristec+</b>
        {' '}
        que tenham ou venham a firmar contratos e convênios de parcerias comerciais para
        a entrega de nossos serviços;
      </li>
      <li>
        empresas que são ou venham a ser controladas, controladoras, coligadas ou estar
        sob controle comum da
        {' '}
        <b>Juristec+</b>
        ; e
      </li>
      <li>
        pessoas e empresas interessadas na aquisição de unidades de negócio da
        {' '}
        <b>Juristec+</b>
        , ativos ou participações societárias da
        {' '}
        <b>Juristec+</b>
        , constituição de novas sociedade ou realização de investimentos.
      </li>
    </ol>
    <p>
      <b>Entre a Juristec+ e seus clientes.</b>
      {' '}
      Os dados pessoais também podem ser recebidos de clientes e representantes de clientes,
      diretos e indiretos da Juristec+ ou com eles compartilhados (“Clientes”).
    </p>
    <p>
      <b>Entre a Juristec+ e autoridades públicas.</b>
      {' '}
      Os dados também podem ser recebidos pela
      {' '}
      <b>Juristec+</b>
      {' '}
      de autoridades e entidades públicas e com elas compartilhadas, incluindo órgãos e
      autoridades da administração pública direta e entidades da administração pública
      indireta de qualquer dos Poderes da União, dos Estados, do Distrito Federal e dos
      Municípios, ou demais entidades que, por lei, possam ser caracterizadas como integrantes
      do setor público, incluindo ministérios, institutos, secretarias, empresas públicas,
      sociedades de economia mista, autarquias, agências reguladoras, alfândegas, fundações
      públicas, cartórios, universidades públicas, etc. (“Setor Público” ou “Autoridades
      Públicas”) para:
    </p>
    <ol type="I">
      <li>
        cumprimento de determinações legais, judiciais, arbitrais, administrativas e
        regulatórias; e
      </li>
      <li>
        adoção de medidas necessárias para o exercício regular de direitos da
        {' '}
        <b>Juristec+</b>
        , Clientes e Parceiros, incluindo para atuação em processos judiciais, arbitrais e
        administrativos.
      </li>
    </ol>
    <p>
      <b>Obtidos de fontes públicas.</b>
      {' '}
      Os dados pessoais poderão ser provenientes de fontes públicas ou tornadas manifestamente
      públicas pelo Titular, como informações constantes em sites de entidades do Setor Público,
      banco de dados públicos ou certidões, processos judiciais e procedimentos administrativos
      públicos.
    </p>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      {' '}
      demandará no compartilhamento de dados pessoais com Parceiros e Clientes a estrita
      observância das obrigações constantes da LGPD, seja quando tais Clientes e Parceiros atuarem
      como emitentes dos dados, receptores, Controladores, Operadores ou Suboperadores. Cabe
      exclusivamente ao Cliente ou Parceiro garantir o cumprimento de suas obrigações junto ao
      Titular e à LGPD, não se responsabilizando a
      {' '}
      <b>Juristec+</b>
      {' '}
      por atos realizados por Clientes e Parceiros em descumprimento das obrigações assumidas
      perante a Juristec+ e a legislação em vigor.
    </p>

    <h4>Cookies e outras tecnologias semelhantes</h4>
    <p>
      Cookies são pequenos arquivos de texto enviados e armazenados no computador e demais
      dispositivos móveis dos usuários de internet. Estes pequenos arquivos servem para
      reconhecer, acompanhar e armazenar preferências e outras informações de navegação de
      um usuário na internet.
    </p>
    <p>
      Em geral, os cookies são utilizados para:
    </p>
    <ul>
      <li>
        Proporcionar serviços, lembrando quem é o usuário e quais são seus hábitos de
        navegação, além de acessar informações de cadastro;
      </li>
      <li>
        Medir certos padrões de navegação, mapeando quais áreas do site ou plataforma foram
        visitados e hábitos de visita como um todo; e
      </li>
      <li>
        Facilitar e agilizar o preenchimento de formulários.
      </li>
    </ul>
    <p>
      Os cookies podem ser divididos em categorias de uso, como, por exemplo:
    </p>
    <ul>
      <li>
        <b>Cookies Estritamente Necessários:</b>
        {' '}
        aqueles necessários para o funcionamento e segurança do site ou plataforma. Esses cookies
        permitem funcionalidades essenciais, tais como segurança, verificação de identidade e gestão
        de rede (por exemplo, cookies de segurança para autenticar usuários, evitar a utilização
        fraudulenta de credenciais de login e proteger os dados do usuário de terceiros não
        autorizados);
      </li>
      <li>
        <b>Cookies de Desempenho:</b>
        {' '}
        Esses cookies normalmente coletam informações de forma anônima e visam a melhoria do
        desempenho do site ou plataforma, como, por exemplo, o número de visitantes de uma página,
        como os visitantes chegaram ao site e as páginas acessadas;
      </li>
      <li>
        <b>Cookies de Funcionalidade:</b>
        {' '}
        Os cookies desta categoria permitem que o site ou a plataforma se lembre de informações
        sobre o comportamento e preferências do usuário.
      </li>
      <li>
        <b>Cookies de Publicidade:</b>
        {' '}
        Estes cookies são usados por empresas para construir um perfil sobre os interesses dos
        usuários e mostrar-lhes anúncios relevantes em websites próprios e de terceiros.
      </li>
    </ul>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      , no exercício de suas atividades, poderá utilizar-se de cookies próprios ou de Parceiros
      prestadores de serviços de tecnologia, como Grupo Alphabet, Grupo Meta Platforms, Grupo
      Zendesk, Grupo Thomson Reuters, Grupo RD Station, Grupo Microsoft, Grupo Oracle e demais
      empresas prestadoras de serviços de tecnologia da informação, gerenciamento de sites,
      gerenciamento de e-mails, segurança da informação, banco de dados, suporte etc.
    </p>
    <p>
      Os cookies podem ser desabilitados pelo próprio usuário, alterando-se as configurações do
      seu navegador de internet. Em tais casos, isso poderá comprometer a experiência e prejudicar
      o funcionamento dos sites e plataformas disponibilizados pela
      {' '}
      <b>Juristec+</b>
      .
    </p>
    <p>
      Para fins desta
      {' '}
      <b>Política de Privacidade e Proteção de Dados Pessoais</b>
      , cookies e outras tecnologias semelhantes incluem arquivos de texto enviados e armazenados
      no computador e demais dispositivos móveis dos usuários de internet, informações obtidas
      pelo protocolo de comunicação “Protocolo de Transferência de Hipertexto” (HTTP) e tecnologias
      conexas, que permitem, entre outros, a obtenção de informações de uso e histórico de
      navegação, endereço de protocolo de rede (I.P.), informações de rede e conexão (incluindo
      endereços de ISPs), identificadores de dispositivo e navegador (incluindo dispositivo,
      aplicativo ou tipo de navegador, versão, tipo de plug-in, sistema operacional, agente de
      usuário, idioma e configurações de fuso horário, além de outras informações técnicas),
      identificadores e dados de localização aproximada constantes de dispositivos eletrônicos
      (“Cookies e Tecnologias Semelhantes”).
    </p>

    <h3 className="section">3. Princípios da LGPD que observamos</h3>
    <p>
      Na realização de qualquer Tratamento de dado pessoal, nós da
      {' '}
      <b>Juristec+</b>
      {' '}
      adotamos os seguintes princípios, cuidados e cautelas:
    </p>
    <p>
      <b>Finalidade:</b>
      {' '}
      O Tratamento dos dados somente será realizado pela
      {' '}
      <b>Juristec+</b>
      {' '}
      para propósitos legítimos, específicos, explícitos e informados ao Titular em termos de
      consentimento específicos ou na presente
      {' '}
      <b>Política de Privacidade e Proteção de Dados Pessoais</b>
      , sem possibilidade de Tratamento posterior que não atenda tais finalidades.
    </p>
    <p>
      <b>Adequação:</b>
      {' '}
      O Tratamento que realizamos é compatível com as finalidades informadas ao Titular.
    </p>
    <p>
      <b>Necessidade:</b>
      {' '}
      O Titular dos dados tem direito à limitação do Tratamento ao mínimo necessário para a
      realização de suas finalidades, com abrangência dos dados pertinentes, proporcionais
      e não excessivos em relação às finalidades do Tratamento.
    </p>
    <p>
      <b>Livre Acesso:</b>
      {' '}
      É garantido ao Titular consulta facilitada e gratuita sobre a forma e a duração do
      Tratamento que realizamos como Controlador, bem como sobre a integralidade de seus dados
      pessoais, a ser realizada diretamente ao Encarregado da
      {' '}
      <b>Juristec+</b>
      , no e-mail privacidade_de_dados@juristecplus.com.
    </p>
    <p>
      <b>Qualidade dos Dados:</b>
      {' '}
      É garantido ao Titular o direito à exatidão, clareza, relevância e atualização dos dados,
      de acordo com a necessidade e para o cumprimento da finalidade de seu Tratamento.
    </p>
    <p>
      <b>Transparência:</b>
      {' '}
      É garantido ao Titular o direito a informações claras, precisas e facilmente acessíveis
      sobre a realização do Tratamento e os respectivos agentes de tratamento, observados os
      segredos comercial e industrial da Juristec+, Clientes e Parceiros.
    </p>
    <p>
      <b>Segurança:</b>
      {' '}
      A
      {' '}
      <b>Juristec+</b>
      {' '}
      adota, dentro do seu Sistema de Gestão de Privacidade da Informação, medidas técnicas e
      administrativas aptas a proteger os dados pessoais de acessos não autorizados e de
      situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou
      difusão.
    </p>
    <p>
      <b>Prevenção:</b>
      {' '}
      A
      {' '}
      <b>Juristec+</b>
      {' '}
      preza pela adoção de medidas para prevenção à ocorrência de danos em virtude do Tratamento
      de dados pessoais.
    </p>
    <p>
      <b>Não discriminação:</b>
      {' '}
      A
      {' '}
      <b>Juristec+</b>
      {' '}
      veda a realização de Tratamento de dados pessoais para fins discriminatórios ilícitos
      ou abusivos.
    </p>
    <p>
      <b>Responsabilização e Prestação de Contas:</b>
      {' '}
      Por meio do seu Sistema de Gestão de Privacidade da Informação, seu Programa de Proteção
      de Dados Pessoais e da presente
      {' '}
      <b>Política de Privacidade e Proteção de Dados Pessoais</b>
      , a
      {' '}
      <b>Juristec+</b>
      {' '}
      demonstra a adoção de medidas eficazes e capazes de comprovar a observância e o cumprimento
      das normas de proteção de dados pessoais e, inclusive, da eficácia dessas medidas.
    </p>

    <h3 className="section">4. Dados que Tratamos e Suas Finalidades</h3>
    <p>
      Num ambiente empresarial, dados pessoais são constantemente tratados para o exercício das
      atividades da empresa. Aqui na
      {' '}
      <b>Juristec+</b>
      {' '}
      não é diferente. O tratamento de dados pessoais é realizado desde nossa área de Recursos
      Humanos, quando temos que coletar informações de nossos colaboradores, no nosso departamento
      financeiro, quando temos que coletar informações bancárias para realizar e receber pagamentos,
      na nossa área de marketing, para divulgação de conteúdo a pessoas interessadas, na execução
      de nossos serviços, entre outros.
    </p>
    <p>
      Veja abaixo quais dados pessoais são tratados em nossas atividades e para quais finalidades:
    </p>
    <ol type="a">
      <li>
        Dados pessoais tratados para realização de login de acesso e segurança de nossos sites,
        plataformas, softwares e demais sistemas da
        {' '}
        <b>Juristec+</b>
        :
        <p>
          A
          {' '}
          <b>Juristec+</b>
          {' '}
          poderá, mediante consentimento do Titular ou dentro das demais hipóteses previstas na
          LGPD, incluindo as hipóteses de prevenção à fraude e garantia à segurança do Titular,
          tratar os seguintes dados pessoais de usuários e visitantes de nossos sites,
          plataformas, softwares e demais sistemas da
          {' '}
          <b>Juristec+</b>
          , para a finalidade de garantir a identificação e segurança de usuários com nossos
          serviços:
        </p>
        <ol type="I">
          <li>
            nome completo, nome de tratamento, foto de perfil, gênero, data de nascimento,
            nacionalidade, naturalidade e informações de identidade, como informações de
            identificação emitidas pelo governo, identificadores de impostos, números de seguro
            social, outros identificadores emitidos pelo governo e dados semelhantes.
          </li>
          <li>
            números de telefone e de celular, endereços de e-mail e endereços de residência.
          </li>
          <li>
            login, senha e outros métodos e funções de autenticação;
          </li>
          <li>
            Além dos dados pessoais acima, a utilização de Cookies e Tecnologias Semelhantes
            necessários para funcionamento e segurança de nossos sites e plataformas.
          </li>
        </ol>
      </li>
      <li>
        Dados pessoais tratados para garantia da execução de nossos serviços, prestação de
        suporte, realização de cobrança, adoção de campanhas de marketing, vendas e melhor
        experiência de usuários e visitantes:
        <p>
          A
          {' '}
          <b>Juristec+</b>
          {' '}
          poderá, mediante consentimento do Titular ou dentro das demais hipóteses previstas
          na LGPD, incluindo prevenção à fraude e garantia à segurança do Titular e apoio e
          promoção de nossas atividades, tratar os seguintes dados pessoais de titulares para
          a finalidade de garantir a execução de nossos serviços, prestação de suporte,
          realização de cobrança, adoção de campanhas de marketing, vendas e melhor experiência
          de usuários e visitantes:
        </p>
        <ol type="I">
          <li>
            nome completo, nome de tratamento, foto de perfil, gênero, data de nascimento,
            nacionalidade, naturalidade e informações de identidade, como informações de
            identificação emitidas pelo governo, identificadores de impostos, números de
            seguro social, outros identificadores emitidos pelo governo e dados semelhantes.
          </li>
          <li>
            informações de perfil pessoal e profissional, como estado civil, cargo, profissão,
            formação, empregador ou organizações das quais o Titular é membro e dados
            semelhantes.
          </li>
          <li>
            números de telefone e de celular, endereços de e-mail e endereços de residência.
          </li>
          <li>
            E-mails, mensagens de texto e voz, realização de chamadas de vídeo e voz, documentos
            encaminhados pelo Titular e outros dados e comunicações semelhantes durante as
            interações realizadas com a equipe da
            {' '}
            <b>Juristec+</b>
            {' '}
            e Parceiros, inclusive durante a realização de cursos e treinamento.
          </li>
          <li>
            Informações financeiras, como histórico de compras, endereço de cobrança, informações
            de cartão de crédito, número de conta bancária e dados semelhantes.
          </li>
          <li>
            Além dos dados pessoais acima, a utilização de Cookies e Tecnologias Semelhantes: (a)
            necessários para funcionamento e segurança de nossos sites e plataformas; (b) que
            visem a melhoria do desempenho de nossos sites ou plataformas; (c) que permitam que
            nossos sites ou plataformas obtenham informações sobre o comportamento e preferências
            de visitantes e usuários; e (d) para identificação de perfis de interesses dos usuários
            e mostrar-lhes anúncios relevantes em websites próprios e de terceiros.
          </li>
        </ol>
      </li>
      <li>
        Dados pessoais tratados para fins de cadastro, execução de serviços, relacionamento e
        realização de transações financeiras com nossos colaboradores, Parceiros e Clientes:
        <p>
          A
          {' '}
          <b>Juristec+</b>
          {' '}
          poderá, mediante consentimento do Titular ou dentro das demais hipóteses previstas na
          LGPD, incluindo quando necessário, a pedido do Titular, para a execução de contrato ou de
          procedimentos preliminares relacionados a contrato do qual seja parte o Titular, tratar
          os seguintes dados pessoais de colaboradores, Parceiros, Clientes e respectivos
          representantes para a finalidade de realizar cadastros, executar serviços, firmar
          contratos, trocar comunicações e realizar transações financeiras:
        </p>
        <ol type="I">
          <li>
            nome completo, nome de tratamento, foto de perfil, gênero, data de nascimento,
            nacionalidade, naturalidade e informações de identidade, como informações de
            identificação emitidas pelo governo, identificadores de impostos, números de
            seguro social, outros identificadores emitidos pelo governo e dados semelhantes.
          </li>
          <li>
            números de telefone e de celular, endereços de e-mail e endereços de residência.
          </li>
          <li>
            informações de perfil pessoal e profissional, como estado civil, cargo, profissão,
            formação, currículo, histórico escolar, cópia de certificados educacionais e de
            registros perante entidades de classe, empregador ou organizações das quais o
            Titular é membro e dados semelhantes.
          </li>
          <li>
            e-mails, mensagens de texto e voz, realização de chamadas de vídeo e voz, documentos
            encaminhados pelo Titular e outros dados e comunicações semelhantes durante as
            interações realizadas com a equipe da
            {' '}
            <b>Juristec+</b>
            {' '}
            e Parceiros, inclusive durante a realização de cursos e treinamento.
          </li>
          <li>
            informações financeiras, como endereço de cobrança, informações de cartão de crédito,
            número de conta bancária, comprovantes de bens e renda e dados semelhantes.
          </li>
          <li>
            informações obtidas de fontes públicas ou tornadas manifestamente públicas pelo Titular,
            como aquelas constantes em sites de entidades do Setor Público, banco de dados públicos
            ou certidões, processos e procedimentos administrativos públicos.
          </li>
        </ol>
      </li>
      <li>
        Dados pessoais tratados durante a execução de nossos serviços a Clientes.
        <p>
          A
          {' '}
          <b>Juristec+</b>
          {' '}
          poderá, dentro das hipóteses previstas na LGPD, incluindo as hipóteses de dados obtidos de
          fontes públicas ou tornados manifestamente públicos pelo Titular, tratar os seguintes
          dados pessoais de Titular durante a execução dos serviços ao Cliente:
        </p>
        <ol type="I">
          <li>
            nome completo, nome de tratamento, gênero, data de nascimento, nacionalidade,
            naturalidade e informações de identidade, como informações de identificação emitidas
            pelo governo, identificadores de impostos, números de seguro social, outros
            identificadores emitidos pelo governo e dados semelhantes.
          </li>
          <li>
            números de telefone e de celular, endereços de e-mail e endereços de residência.
          </li>
          <li>
            informações de perfil pessoal e profissional, como estado civil, cargo, profissão,
            formação.
          </li>
          <li>
            informações financeiras, como endereço de cobrança, comprovantes de bens e renda,
            dívidas, créditos, saldo devedores, estado de solvência e dados semelhantes.
          </li>
          <li>
            informações obtidas de fontes públicas ou tornadas manifestamente públicas pelo Titular,
            como aquelas constantes em sites de entidades do Setor Público, banco de dados públicos
            ou certidões, processos e procedimentos administrativos públicos.
          </li>
          <li>
            demais dados pessoais tratados por Parceiros e Clientes na qualidade de Controladores,
            situação na qual a
            {' '}
            <b>Juristec+</b>
            , como Operadora ou Suboperadora, demandará a obtenção do respectivo consentimento do
            Titular ou observância das demais hipóteses previstas na LGPD.
          </li>
        </ol>
      </li>
    </ol>

    <h3 className="section">5. Compartilhamento, Duração do Tratamento e Eliminação dos Dados Pessoais</h3>
    <p>
      Os dados constantes dos itens 4-a) a 4-c) acima poderão ser compartilhados entre a
      {' '}
      <b>Juristec+</b>
      {' '}
      e os Parceiros envolvidos na operação de Tratamento de maneira física ou eletrônica. Os dados
      também poderão ser compartilhados com o Cliente que o Titular seja integrante da organização
      e com o próprio Titular. Por fim, os dados poderão ser excepcionalmente compartilhados com
      Autoridades Públicas ou terceiros quando necessário para o cumprimento de obrigação legal ou
      regulatória, para o exercício regular de direitos em processo judicial, administrativo ou
      arbitral ou nas demais hipóteses permitidas pela LGPD.
    </p>
    <p>
      Os dados constantes do item 4-d) acima poderão ser compartilhados entre a
      {' '}
      <b>Juristec+</b>
      , Parceiros e Clientes envolvidos na operação de Tratamento de maneira física ou eletrônica.
      Por fim, os dados poderão ser excepcionalmente compartilhados com Autoridades Públicas ou
      terceiros quando necessário para o cumprimento de obrigação legal ou regulatória, para o
      exercício regular de direitos em processo judicial, administrativo ou arbitral ou nas demais
      hipóteses permitidas pela LGPD.
    </p>
    <p>
      Os dados poderão ser processados, armazenados e arquivados tanto pela
      {' '}
      <b>Juristec+</b>
      {' '}
      quanto por seus Clientes e Parceiros, enquanto durar a execução de nossos serviços e, após
      isto, durante o prazo prescricional necessário para resguardar direitos da
      {' '}
      <b>Juristec+</b>
      , de seus Clientes e Parceiros ou enquanto necessário para o cumprimento de obrigações legais
      ou regulamentares pela Juristec+, Clientes e Parceiros.
    </p>
    <p>O término do Tratamento se dará:</p>
    <ol type="a">
      <li>
        quando atingida a sua finalidade;
      </li>
      <li>
        mediante solicitação expressa do Titular para dados pessoais em que o consentimento para
        Tratamento é necessário;
      </li>
      <li>
        mediante determinação da ANPD, em caso de violação ao ordenamento jurídico e à LGPD.
      </li>
    </ol>
    <p>
      Finalizado o Tratamento, os dados pessoais serão eliminados pela
      {' '}
      <b>Juristec+</b>
      , salvo nas hipóteses de:
    </p>
    <ol type="a">
      <li>
        manutenção necessária para o cumprimento de obrigações legais ou regulatórias pela
        {' '}
        <b>Juristec+</b>
        , Clientes e Parceiros;
      </li>
      <li>
        transferência a terceiro, desde que respeitados os requisitos de tratamento de dados
        dispostos na LGPD;
      </li>
      <li>
        uso exclusivo da
        {' '}
        <b>Juristec+</b>
        , vedado seu acesso por terceiro, e desde que sujeitos a processo de anonimização; e
      </li>
      <li>
        demais hipóteses permitidas pelo ordenamento jurídico e pela LGPD.
      </li>
    </ol>

    <h3 className="section">6. Direitos do Titular, Segurança e Responsabilidade da Juristec+</h3>
    <h4>Direitos constantes da LGPD</h4>
    <p>
      A LGPD, confere, em seu art. 18, os seguintes direitos ao Titular:
    </p>
    <p>
      <b>Art. 18.</b>
      {' '}
      O titular dos dados pessoais tem direito a obter do controlador, em relação aos dados do
      titular por ele tratados, a qualquer momento e mediante requisição:
    </p>
    <ol type="I">
      <li>
        Confirmação da existência de tratamento;
      </li>
      <li>
        Acesso aos dados;
      </li>
      <li>
        Correção de dados incompletos, inexatos ou desatualizados;
      </li>
      <li>
        Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados
        em desconformidade com o disposto nesta Lei;
      </li>
      <li>
        Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição
        expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos
        comercial e industrial;
      </li>
      <li>
        Eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas
        hipóteses previstas no art. 16 desta Lei;
      </li>
      <li>
        informação das entidades públicas e privadas com as quais o controlador realizou uso
        compartilhado de dados;
      </li>
      <li>
        informação sobre a possibilidade de não fornecer consentimento e sobre as consequências
        da negativa;
      </li>
      <li>
        Revogação do consentimento, nos termos do § 5º do art. 8º desta Lei.
      </li>
    </ol>
    <p>
      § 1º O titular dos dados pessoais tem o direito de peticionar em relação aos seus dados
      contra o controlador perante a autoridade nacional.
    </p>
    <p>
      § 2º O titular pode opor-se a tratamento realizado com fundamento em uma das hipóteses de
      dispensa de consentimento, em caso de descumprimento ao disposto nesta Lei.
    </p>
    <p>
      § 3º Os direitos previstos neste artigo serão exercidos mediante requerimento expresso do
      titular ou de representante legalmente constituído, a agente de tratamento.
    </p>
    <p>
      § 4º Em caso de impossibilidade de adoção imediata da providência de que trata o § 3º deste
      artigo, o controlador enviará ao titular resposta em que poderá:
    </p>
    <ol type="I">
      <li>
        Comunicar que não é agente de tratamento dos dados e indicar, sempre que possível, o
        agente; ou
      </li>
      <li>
        Indicar as razões de fato ou de direito que impedem a adoção imediata da providência.
      </li>
    </ol>
    <p>
      § 5º O requerimento referido no § 3º deste artigo será atendido sem custos para o titular,
      nos prazos e nos termos previstos em regulamento.
    </p>
    <p>
      § 6º O responsável deverá informar, de maneira imediata, aos agentes de tratamento com os
      quais tenha realizado uso compartilhado de dados a correção, a eliminação, a anonimização
      ou o bloqueio dos dados, para que repitam idêntico procedimento, exceto nos casos em que
      esta comunicação seja comprovadamente impossível ou implique esforço desproporcional.
    </p>
    <p>
      § 7º A portabilidade dos dados pessoais a que se refere o inciso V do caput deste artigo não
      inclui dados que já tenham sido anonimizados pelo controlador.
    </p>
    <p>
      § 8º O direito a que se refere o § 1º deste artigo também poderá ser exercido perante os
      organismos de defesa do consumidor.
    </p>
    <p>
      O Titular poderá, a qualquer momento, contatar a
      {' '}
      <b>Juristec+</b>
      , através do e-mail do Encarregado: privacidade_de_dados@juristecplus.com para pleitear o
      exercício de quaisquer dos direitos constantes na LGPD, incluindo os direitos de
      {' '}
      <b>(i)</b>
      {' '}
      acessar seus dados pessoais;
      {' '}
      <b>(ii)</b>
      {' '}
      retificar seus dados pessoais;
      {' '}
      <b>(iii)</b>
      {' '}
      restringir a extensão do tratamento;
      {' '}
      <b>(iv)</b>
      {' '}
      revogar o consentimento dado;
      {' '}
      <b>(v)</b>
      {' '}
      excluir seus dados pessoais; ou
      {' '}
      <b>(vi)</b>
      {' '}
      pleitear os demais direitos constantes na LGPD.
    </p>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      {' '}
      reserva-se o direito de não atender parcial ou integralmente pedidos do Titular previsto
      no art. 18 e demais dispositivos da LGDP nas hipóteses em que:
      {' '}
      <b>(i)</b>
      {' '}
      a própria LGPD permitir à
      {' '}
      <b>Juristec+</b>
      {' '}
      solução diversa da pleiteada pelo Titular;
      {' '}
      <b>(ii)</b>
      {' '}
      alterações ou especificações legislativas e/ou regulamentares liberarem a
      {' '}
      <b>Juristec+</b>
      {' '}
      da obrigação pleiteada pelo Titular, como, por exemplo, disposições constantes da Resolução
      CD/ANPD nº 2/2022; ou
      {' '}
      <b>(iii)</b>
      {' '}
      os pleitos do Titular estiverem em confronto com o ordenamento jurídico e/ou jurisprudência
      do Poder Judiciário brasileiro.
    </p>
    <h4>Segurança e tratamento de dados pessoais</h4>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      dá total prioridade para a segurança no tratamento de dados pessoais. Nosso Sistema de Gestão
      de Privacidade da Informação contém as políticas e controles necessários desenvolvidos para
      proteger a segurança de dados pessoais do Titular.
    </p>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      {' '}
      adota práticas de mercado aderentes ao seu porte e perfil de negócio. Ainda, a
      {' '}
      <b>Juristec+</b>
      {' '}
      tem o compromisso de constantemente atualizar e aprimorar nossas regras e controles de
      segurança da informação, de maneira a proteger dentro dos padrões de mercado os dados
      pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição,
      perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.
    </p>
    <p>
      Em conformidade e nos termos do art. 48 da LGPD, a
      {' '}
      <b>Juristec+</b>
      {' '}
      comunicará ao Titular e à ANPD a ocorrência de incidente de segurança que possa acarretar
      risco ou dano relevante ao Titular.
    </p>
    <h4>Responsabilidades da Juristec+</h4>
    <p>
      Como Controladora, Operadora ou Suboperadora no Tratamento de dados pessoais, a
      {' '}
      <b>Juristec+</b>
      {' '}
      poderá ser obrigada a reparar o Titular e terceiros por danos patrimoniais, morais,
      individuais ou coletivos causados durante o Tratamento, em violação à LGPD.
    </p>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      {' '}
      não será responsável, nos termos do art. 43 da LGPD e demais legislação aplicável,
      por quaisquer danos patrimoniais, morais, individuais ou coletivos relacionados a
      alegadas violações à LGPD quando provar que
      {' '}
      <b>(i)</b>
      {' '}
      não realizou o tratamento de dados pessoais que lhe é atribuído;
      {' '}
      <b>(ii)</b>
      {' '}
      embora tenha realizado o tratamento de dados pessoais que lhe é atribuído, não houve
      violação à legislação de proteção de dados; ou
      {' '}
      <b>(iii)</b>
      {' '}
      o dano é decorrente de culpa exclusiva do Titular ou de terceiros.
    </p>

    <h3 className="section">7. Proteção de Dados Pessoais de Crianças e Adolescentes</h3>
    <p>
      Os serviços prestados pela
      {' '}
      <b>Juristec+</b>
      {' '}
      não são destinados a menores de 18 (dezoito) anos de idade.
    </p>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      , como regra geral,
      {' '}
      <b>não realiza o tratamento de dados pessoais de menores de 18 (dezoito) anos</b>
      .
    </p>
    <p>
      Excepcionalmente, poderemos realizar o tratamento de dados de crianças ou adolescentes
      diretamente ou na qualidade de Operadora ou Suboperadora de nossos Clientes ou Parceiros.
    </p>
    <p>
      Em tais casos, nós observaremos estritamente as regras constantes da LGPD, assim como
      demandaremos o seu cumprimento por nossos Clientes e Parceiros. Somente deverão ser
      tratados dados de menores de 18 (dezoito) anos quando devidamente anonimizados ou para
      atender ao seu melhor interesse, com o consentimento específico e em destaque dado por
      pelo menos um dos pais ou pelo responsável legal, observadas as demais previsões constantes
      da LGPD.
    </p>

    <h3 className="section">8. Compartilhamento Internacional de Dados Pessoais</h3>
    <p>
      Os dados pessoais de Titulares, bem como Cookies e Tecnologias Semelhantes, poderão ser
      transferidos, armazenados e tratados no Brasil ou em território estrangeiro pela
      {' '}
      <b>Juristec+</b>
      , Clientes e Parceiros, de acordo com essa
      {' '}
      <b>Política de Privacidade e Proteção de Dados</b>
      .
    </p>
    <p>
      A
      {' '}
      <b>Juristec+</b>
      apenas realizará a transferência internacional de dados pessoais
      {' '}
      <b>(i)</b>
      {' '}
      para países ou organismos internacionais que proporcionem grau de proteção de dados pessoais
      adequado ao previsto na LGPD ou
      {' '}
      <b>(ii)</b>
      {' '}
      quando observadas condições para o cumprimento dos princípios e direitos do Titular e do
      regime de proteção de dados previstos na LGPD.
    </p>

    <h3 className="section">9. Alterações nesta Política de Privacidade e Proteção de Dados Pessoais</h3>
    <p>
      A presente
      {' '}
      <b>Política de Privacidade e Proteção de Dados</b>
      {' '}
      poderá ser atualizada e aprimorada ao longo do tempo pela
      {' '}
      <b>Juristec+</b>
      .
    </p>
    <p>
      Solicitamos que o Titular verifique com frequência a atualização desta política no endereço
      eletrônico
      <a
        href="https://juristecplus.com/politica-de-privacidade/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://juristecplus.com/politica-de-privacidade/
      </a>
      ou em outro local disponibilizado pela Juristec+.
    </p>
  </>
);

export default PolicyTR;
