import React, {
  useState, useContext,
} from 'react';

// contextos
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { AuthContext } from '../../context/AuthProvider';
import { AlertContext } from '../../context/AlertProvider';
import { ModalContext } from '../../context/ModalProvider';
import useRecaptchaV3 from '../../hooks/useRecaptchaV3';
import useRecaptchaV2 from '../../hooks/useRecaptchaV2';
//
import InputTextLine from '../../juristec-ui/core/InputTextLine';
import Button from '../../juristec-ui/core/Button';
import IconButton from '../../juristec-ui/core/IconButton';
import Loader from '../../juristec-ui/core/Loader';
import MfaLogin from '../../components/ProgressiveModals/MfaLogin';

import firebase from '../../utils/firebase';
import Multifactor from '../../utils/multifactor';
import CookiesMessage from '../../components/CookiesMessage';

import {
  ContainerLoginPage,
  Paper,
  FormColumn,
  FormRow,
  Logo,
  FormHeader,
  FormSubHeader,
} from './styled/ExtractorCredsPage.styled';

import { Visibility, VisibilityOff } from '../../juristec-ui/icons';
import handleLoginErrors from '../../utils/firestoreErrors/HandleLoginErrors';
import { compactString } from '../../juristec-ui/utils/functions/formatString';
import { verifyEmail, verifyInput } from '../../juristec-ui/utils/validators/inputTextValidators';
import { CreateInstanceFirstLoginMessages } from '../../options';

import InstanceProvider, { InstanceContext } from '../../context/InstanceProvider';
import urlHash from '../../utils/functions/urlHash';

const getParams = (h) => {
  const str = urlHash(h, true);
  return { hashcode: str };
};

function ExtractorCredsPage() {
  const { checkRecaptchaToken } = useContext(AuthContext);

  const theme = useTheme();
  const recaptcha = useRecaptchaV3();
  const v2Verifier = useRecaptchaV2();
  const { hash } = useParams();
  const { hashcode } = getParams(hash);
  const { setModalConfig, closeModal } = useContext(ModalContext);
  const { setAlertConfig } = useContext(AlertContext);
  const { state: instanceState, instanceAPI } = useContext(InstanceContext);

  const [email, setEmail] = useState({ value: '', error: true, errorMsg: '' });
  const [password, setPassword] = useState({ value: '', error: true, errorMsg: '' });
  const [consumerKey, setConsumerKey] = useState({ value: '', error: true, errorMsg: '' });
  const [consumerSecret, setConsumerSecret] = useState({ value: '', error: true, errorMsg: '' });
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [instanceName, setInstanceName] = useState('');

  const getCompanyName = async () => {
    const res = await instanceAPI.getInstance(hashcode);
    if (res?.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
    }
    const companyName = res?.instance?.name;
    return companyName;
  };

  const emailHandle = (val) => {
    const msg = verifyEmail(val, true);
    setEmail({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const passwordHandle = (val) => {
    const msg = verifyInput(val, true, 6);
    setPassword({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const consumerKeyHandle = (val) => {
    const msg = verifyInput(val.trim(), true, 6);
    setConsumerKey({
      value: val.trim(),
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const consumerSecretHandle = (val) => {
    const msg = verifyInput(val.trim(), true, 6);
    setConsumerSecret({
      value: val.trim(),
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const successLogin = async (loginStatus) => {
    if (loginStatus) {
      if (!loginStatus.user.emailVerified) {
        setAlertConfig({
          type: 'error',
          text: 'Você precisa confirmar o seu cadastro',
          child: 'Verifique a sua caixa de e-mail para confirmar o seu cadastro antes de efetuar o login',
        });
      }
      const companyName = await getCompanyName();
      setInstanceName(companyName);
      setLoading(false);
      setLoggedIn(true);
    }
  };

  const errorAlertModal = (error) => {
    if (error.code === 'auth/internal-error' && error.message?.includes('IP address not allowed.')) {
      setLoading(false);
      setAlertConfig({
        type: 'error',
        text: 'Domínio não autorizado',
      });
    } else {
      const { text, message } = handleLoginErrors(error);
      setLoading(false);
      setAlertConfig({
        type: 'error',
        text,
        child: message,
      });
    }
  };

  const login = async (e) => {
    e.preventDefault();
    if (!email.error || !password.error) {
      try {
        setLoading(true);
        const gToken = await recaptcha.getToken('login');

        const v3Validator = await checkRecaptchaToken(gToken);

        if (v3Validator.error) {
          setLoading(false);
          setAlertConfig({
            type: 'error',
            text: 'Acesso não permitido!',
            child: 'Foi detectado tráfego incomum em sua rede de computadores. Tente novamente mais tarde!',
          });
          return;
        }

        // const loginStatus = await firebase.tokenLogin(validator.res.cuToken);
        firebase.login(email.value, password.value)
          .then((loginStatus) => {
            successLogin(loginStatus);
          })
          .catch((error) => {
            setLoading(false);
            if (error.code === 'auth/multi-factor-auth-required') {
              const mfa = new Multifactor(v2Verifier.getVerifier(), error)
                .success((loginStatus) => {
                  successLogin(loginStatus);
                })
                .error((er) => {
                  errorAlertModal(er);
                });
              /* Modal sendCode function */
              setModalConfig({
                title: 'Autenticação multifator',
                children: (
                  <MfaLogin
                    hide={closeModal}
                    mfa={mfa}
                    setLoading={setLoading}
                  />
                ),
              });
            } else {
              errorAlertModal(error);
            }
          });
        // history.replace('/home');
      } catch (error) {
        errorAlertModal(error);
      }
    } else {
      setAlertConfig({
        type: 'error',
        text: 'Acesso não permitido!',
        child: 'Você deve preencher um e-mail e senha válidos. Verifique e tente novamente!',
      });
      emailHandle(email.value);
      passwordHandle(password.value);
    }
  };

  const validateCreds = async (e) => {
    e.preventDefault();
    if (!consumerKey.error && !consumerSecret.error) {
      const res = await instanceAPI.validateApiCredentials(
        consumerKey.value, consumerSecret.value,
      );
      if (!res.error) {
        setAlertConfig({
          type: 'success',
          text: 'Credenciais validadas com sucesso',
        });
      } else {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
      }
    } else {
      setAlertConfig({
        type: 'error',
        text: 'Credenciais inválidas',
        child: 'Verifique os campos e tente novamente',
      });
    }
  };

  const saveCreds = async (e) => {
    e.preventDefault();
    if (!consumerKey.error && !consumerSecret.error) {
      const validateRes = await instanceAPI.validateApiCredentials(
        consumerKey.value, consumerSecret.value,
      );
      if (validateRes.error) {
        setAlertConfig({
          type: 'error',
          text: validateRes.msg,
          child: validateRes.raw,
        });
        return null;
      }
      const res = await instanceAPI.saveApiCredentials(
        hashcode, consumerKey.value, consumerSecret.value,
      );
      if (!res.error) {
        setAlertConfig({
          type: 'success',
          text: 'Credenciais cadastradas com sucesso',
        });
        return null;
      }
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
    } else {
      setAlertConfig({
        type: 'error',
        text: 'Credenciais inválidas',
        child: 'Verifique os campos e tente novamente',
      });
      return null;
    }
    return null;
  };
  return (
    <>
      <ContainerLoginPage>
        {(loading || instanceState.isLoading || !recaptcha.ready)
          && <Loader underText={CreateInstanceFirstLoginMessages} />}
        {!loggedIn ? (
          <>
            <Paper onSubmit={(e) => login(e)}>
              <FormColumn>
                <div className="gap-fix">
                  <Logo alt="logo" src={theme.loginLogo} />
                  <FormHeader>
                    Login
                  </FormHeader>
                  <FormRow>
                    <InputTextLine
                      label="E-mail*"
                      type="email"
                      value={email.value}
                      errorMessage={email.errorMsg}
                      error={email.errorMsg.length > 0}
                      notTransparent={false}
                      onChange={(e) => emailHandle(compactString(e.target.value))}
                      autoComplete="off"
                    />
                  </FormRow>
                  <FormRow>
                    {/* <form onSubmit={login}> */}
                    <div style={{ position: 'relative', width: '100%' }}>
                      <InputTextLine
                        label="Senha*"
                        type={!showPass ? 'password' : 'text'}
                        value={password.value}
                        errorMessage={password.errorMsg}
                        error={password.errorMsg.length > 0}
                        notTransparent={false}
                        onChange={(e) => passwordHandle(compactString(e.target.value))}
                        style={{ paddingRight: '35px' }}
                      />
                      <IconButton
                        style={{
                          position: 'absolute', bottom: 4, right: 2, boxShadow: 'none', padding: 2,
                        }}
                        type="button"
                        variant="pattern"
                        iconColor="black"
                        onMouseDown={() => setShowPass(!showPass)}
                      >
                        {showPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </div>
                  </FormRow>
                  <FormRow>
                    <Button
                      id="sign-in-button"
                      type="submit"
                      textTransform="uppercase"
                      color={theme.primary}
                      size="large"
                      style={{ width: '100%', margin: '.5rem 0 0 0' }}
                    >
                      Login
                    </Button>
                  </FormRow>
                </div>
              </FormColumn>
            </Paper>
          </>
        ) : (
          <>
            <Paper onSubmit={(e) => saveCreds(e)}>
              <FormColumn>
                <div className="gap-fix">
                  <Logo alt="logo" src={theme.loginLogo} />
                  <FormHeader>
                    Cadastrar credenciais do Extrator
                    {' '}
                  </FormHeader>
                  <FormSubHeader>
                    {'Credenciais para a instância: '}
                    <span style={{ color: theme.secondary, fontWeight: 'bold' }}>
                      {instanceName}
                    </span>
                  </FormSubHeader>
                  <FormRow>
                    <InputTextLine
                      label="Consumer Key*"
                      type="text"
                      value={consumerKey.value}
                      errorMessage={consumerKey.errorMsg}
                      error={consumerKey.errorMsg.length > 0}
                      notTransparent={false}
                      onChange={(e) => consumerKeyHandle(compactString(e.target.value))}
                      autoComplete="off"
                    />
                  </FormRow>
                  <FormRow>
                    <div style={{ position: 'relative', width: '100%' }}>
                      <InputTextLine
                        label="Consumer Secret*"
                        type="text"
                        value={consumerSecret.value}
                        errorMessage={consumerSecret.errorMsg}
                        error={consumerSecret.errorMsg.length > 0}
                        notTransparent={false}
                        onChange={(e) => consumerSecretHandle(compactString(e.target.value))}
                        style={{ paddingRight: '35px' }}
                        autoComplete="off"
                      />
                    </div>
                  </FormRow>
                  <FormRow style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                      id="validate-creds-button"
                      loading={loading ? 1 : 0}
                      type="button"
                      variant="outlined"
                      textTransform="uppercase"
                      color={theme.secondary}
                      onClick={(e) => validateCreds(e)}
                      size="large"
                      style={{ width: '100%', margin: '.5rem .5rem 0 0' }}
                    >
                      Testar
                    </Button>
                    <Button
                      id="save-creds-button"
                      loading={loading ? 1 : 0}
                      type="submit"
                      textTransform="uppercase"
                      color={theme.primary}
                      size="large"
                      style={{ width: '100%', margin: '.5rem 0 0 0' }}
                    >
                      Cadastrar
                    </Button>
                  </FormRow>
                </div>
              </FormColumn>
            </Paper>
          </>
        )}
      </ContainerLoginPage>
      <CookiesMessage />
    </>
  );
}

ExtractorCredsPage.propTypes = {};

const ExtractorCredsPageHOC = () => (
  <InstanceProvider>
    <ExtractorCredsPage />
  </InstanceProvider>
);

export default ExtractorCredsPageHOC;
